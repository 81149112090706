import React from "react";
import { Link } from "react-router-dom";

const Contact = () => {
  return (
    <>
      <section className="page-header">
        <div className="overlay">
          <div className="container">
            <h3>Contact us</h3>
            <ul>
              <li>
                <Link to="/">Home</Link>
              </li>
              <li> - </li>
              <li>Contact us</li>
            </ul>
          </div>
        </div>
      </section>

      <section className="contact contact-page">
        <div className="contact-details padding-120">
          <div className="container">
            <div className="row">
              <div className="col-md-4 col-sm-6 col-xs-12">
                <ul>
                  <li className="contact-item ">
                    <span className="icon flaticon-signs" />
                    <div className="content">
                      <h4>Address</h4>
                      <p>
                        Brain Box Solutions, OU Campus, Near IFLU, Hyderabad
                        -500007, Telangana
                      </p>
                    </div>
                  </li>
                  <li className="contact-item">
                    <span className="icon flaticon-smartphone" />
                    <div className="content">
                      <h4>Phone Number</h4>
                      <p>+971 521356139</p>
                    </div>
                  </li>
                  <li className="contact-item">
                    <span className="icon flaticon-message" />
                    <div className="content">
                      <h4>Email </h4>
                      <p>info@brainboxsol.com</p>
                    </div>
                  </li>
                </ul>
              </div>
              <div className="col-md-8 col-sm-6 col-xs-12">
                <form className="contact-form">
                  <input
                    type="text"
                    name="name"
                    placeholder="Your Name"
                    className="contact-input"
                  />
                  <input
                    type="email"
                    name="email"
                    placeholder="Your Email"
                    className="contact-input"
                  />
                  <textarea
                    rows={5}
                    className="contact-input"
                    defaultValue={"Your Messages"}
                  />
                  <Link to="/">
                    <button className="contact-button">Submit</button>
                  </Link>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Contact;
