import React from "react";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <>
      <footer>
        <div className="footer-top">
          <div className="container">
            <div className="row">
              <div className="col-md-5 col-sm-6 col-xs-12">
                <div className="footer-item">
                  <div className="title">
                    <Link to="/">
                      <img
                        src="images/logo2.png"
                        alt="logo"
                        className="img-responsive"
                      />
                    </Link>
                  </div>
                  <div className="footer-about">
                    <p>
                      Our state-of-the-art computer labs come fully equipped
                      with the latest software, hardware, and accessories to
                      meet your every need.
                    </p>
                    {/* <ul>
                      <li>
                        <span>
                          <i className="fa fa-home" aria-hidden="true" />
                        </span>
                        Brain Box Solutions, OU Campus, Near IFLU, <br />{" "}
                        Hyderabad -500007, Telangana
                      </li>
                      <li>
                        <span>
                          <i className="fa fa-phone" aria-hidden="true" />
                        </span>
                        +8801923
                      </li>
                      <li>
                        <span>
                          <i className="fa fa-envelope-o" aria-hidden="true" />
                        </span>
                        info@brainboxsol.com
                      </li>
                    </ul> */}
                  </div>
                </div>
              </div>
              <div className="col-md-3 col-sm-6 col-xs-12">
                <div className="footer-item">
                  <h4 className="title">Latest News</h4>
                  <ul className="footer-post">
                    <li>
                      <div className="image"></div>
                      <div className="content ">
                        <p>
                          <Link to="/">Home</Link>
                        </p>
                      </div>
                    </li>
                    <li>
                      <div className="image"></div>
                      <div className="content ">
                        <p>
                          <Link to="/about">About Us</Link>
                        </p>
                      </div>
                    </li>
                    <li>
                      <div className="image"></div>
                      <div className="content ">
                        <p>
                          <Link to="/services">Services</Link>
                        </p>
                      </div>
                    </li>
                    <li>
                      <div className="image"></div>
                      <div className="content ">
                        <p>
                          <Link to="/gallery">Gallery</Link>
                        </p>
                      </div>
                    </li>
                    <li>
                      <div className="image"></div>
                      <div className="content ">
                        <p>
                          <Link to="/contact">Contact</Link>
                        </p>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>

              <div className="col-md-4 col-sm-6 col-xs-12">
                <div className="footer-item">
                  <div className="footer-about">
                    <h4 className="title">Contact Us </h4>
                    <ul>
                      <li>
                        <span>
                          <i className="fa fa-home" aria-hidden="true" />
                        </span>{" "}
                        Brain Box Solutions, OU Campus, Near IFLU, <br />{" "}
                        Hyderabad -500007, Telangana
                      </li>
                      <li>
                        <span>
                          <i className="fa fa-phone" aria-hidden="true" />
                        </span>
                        +971 521356139
                      </li>
                      <li>
                        <span>
                          <i className="fa fa-envelope-o" aria-hidden="true" />
                        </span>
                        info@brainboxsol.com
                      </li>
                    </ul>
                  </div>
                </div>
              </div>

              {/* <div className="col-md-4 col-sm-6 col-xs-12">
                <div className="footer-item">
                  <h4 className="title">Twitter Widget</h4>
                  <ul className="twitter-post">
                    <li>
                      <div className="icon">
                        <i className="fa fa-twitter" aria-hidden="true" />
                      </div>
                      <div className="content">
                        <p>
                          Raritas etiam processus them dynamicus sequitur
                          mutatem education theme
                        </p>
                      </div>
                    </li>
                    <li>
                      <div className="icon">
                        <i className="fa fa-twitter" aria-hidden="true" />
                      </div>
                      <div className="content">
                        <p>
                          Duis autem veleum iriu dolor hendrerit in vulputate
                          velit
                        </p>
                      </div>
                    </li>
                    <li>
                      <div className="icon">
                        <i className="fa fa-twitter" aria-hidden="true" />
                      </div>
                      <div className="content">
                        <p>@frankdoe amber tempor cum soluta nobis eleifend</p>
                      </div>
                    </li>
                  </ul>

                 
                </div>
              </div> */}
            </div>
            {/* row */}
          </div>
          {/* container */}
        </div>
        {/* footer top */}
        <div className="footer-bottom">
          <div className="container">
            <div className="row">
              <div className="col-md-6 col-sm-6 col-xs-12">
                <p>
                  © 2023. Powered By
                  <a href="https://dexterous.in" target="_blanck"> dexterous.in
                  </a>
                </p>
              </div>
              <div className="col-md-6 col-sm-6 col-xs-12">
                <ul className="social-default">
                  <li>
                    <Link to="#">
                      <i className="fa fa-facebook" aria-hidden="true" />
                    </Link>
                  </li>
                  <li>
                    <Link to="#">
                      <i className="fa fa-dribbble" aria-hidden="true" />
                    </Link>
                  </li>
                  <li>
                    <Link to="#">
                      <i className="fa fa-google-plus" aria-hidden="true" />
                    </Link>
                  </li>
                  <li>
                    <Link to="#">
                      <i className="fa fa-twitter" aria-hidden="true" />
                    </Link>
                  </li>
                  <li>
                    <Link to="#">
                      <i className="fa fa-pinterest" aria-hidden="true" />
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;
