import React from "react";
import { Link } from "react-router-dom";

const About = () => {
  return (
    <>
      <section className="page-header">
        <div className="overlay">
          <div className="container">
            <h3>About</h3>
            <ul>
              <li>
                <Link to="/">Home</Link>
              </li>
              <li> - </li>
              <li>About us</li>
            </ul>
          </div>
        </div>
        {/* overlay */}
      </section>

      <section className="teacher-details padding-120 about about-three">
        <div className="container">
          <div className="row ">
            <div className="col-md-6 col-sm-12 col-xs-12">
              <div className="teacher-content">
                <h3 className="text-center">About </h3>
                <p className="mt-lg-5">
                  <br />
                  At Brainbox Solutions, our vision is to empower individuals
                  and organizations with state-of-the-art computer lab rentals
                  that foster innovation, learning, and growth. Our mission is
                  to provide exceptional service and cutting-edge technology,
                  enabling our clients to unlock their full potential. With a
                  passion for excellence, we are committed to delivering
                  tailored solutions that exceed expectations. Our team of
                  dedicated professionals brings together a wealth of experience
                  and expertise in the computer lab rental industry. We take
                  pride in our unwavering commitment to providing an exceptional
                  customer experience, ensuring that every event, training
                  session, or workshop held in our labs is a seamless and
                  memorable one. Join us on this journey as we combine our
                  vision, mission, and passion to create an environment that
                  inspires creativity, collaboration, and success.
                </p>
              </div>
            </div>

            <div className="col-md-6 col-sm-12 col-xs-12">
              <div className="teacher-image ">
                <img
                  src="images/about/lab.jpg"
                  alt="teacher image"
                  className="img-responsive mt"
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default About;
