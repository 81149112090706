import React from "react";
import { Link } from "react-router-dom";


const Services = () => {
  return (
    <>
      <section className="page-header">
        <div className="overlay">
          <div className="container">
            <h3>Services</h3>
            <ul>
              <li>
                <Link to="/">Home</Link>
              </li>
              <li> - </li>
              <li> Services </li>
            </ul>
          </div>
        </div>
      </section>
      <section className="teacher-details padding-120">
        <div className="container">
          <div className="row">
            <div className="col-md-6 col-sm-12 col-xs-12">
              <div className="teacher-content">
                <h4 className="text-center">Services</h4>
                <p className="mt-lg-5">
                  <br />
                  At Brainbox Solutions, we offer comprehensive computer lab
                  rental services designed to meet the diverse needs of our
                  clients. Whether you are planning a training session,
                  workshop, seminar, or any other event, our state-of-the-art
                  computer labs provide the perfect environment for seamless and
                  productive sessions.
                  <br />
                  Equipped with the latest specifications and cutting-edge
                  technology, our labs offer an optimal computing experience. We
                  understand the importance of flexibility, which is why we
                  provide a range of rental options, including hourly, daily,
                  and extended durations, to suit your specific requirements.
                  <br />
                  <br />
                  Our dedicated team is committed to delivering exceptional
                  service, assisting you with setup, technical support, and
                  ensuring a smooth and successful event. Experience the
                  convenience, reliability, and efficiency of our computer lab
                  rental services at Brainbox Solutions."
                </p>
              </div>
            </div>

            <div className="col-md-6 col-sm-12 col-xs-12">
              <div className="teacher-image ">
                <img
                  src="images/about/lab1.jpg"
                  alt="teacher image"
                  className="img-responsive mb"
                />
              </div>
            </div>
          </div>
        </div>
        {/* container */}
      </section>
    </>
  );
};

export default Services;
