import React from "react";

const Locations = () => {
  return (
    <>

      <section className="courses padding-120">
        <div className="container">
          <div className="section-header">
            <h3>Locations</h3>
          </div>
          <div className="row">
            <div className="col-md-4 col-sm-6 col-xs-12">
              <div className="course-item">
                <div className="image">
                  <img
                    src="images/courses/lab4.jpg"
                    alt="course image"
                    className="img-responsive"
                  />
                </div>
                <div className="content">
                  <p>
                    At Brainbox Solutions, we take pride in offering our premier
                    computer lab rental services in multiple convenient and
                    strategic locations. Our extensive network covers prominent
                    cities such as Hyderabad, Bengaluru, Chennai, and Mysuru. By
                    choosing our services, you benefit from the advantages of
                    accessibility, as our labs are situated in prime areas,
                    easily reachable by participants from various parts of the
                    city.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-4 col-sm-6 col-xs-12">
              <div className="course-item">
                <div className="image">
                  <img
                    src="images/courses/lab2.jpg"
                    alt="course image"
                    className="img-responsive"
                  />
                </div>
                <div className="content">
                  <p>
                    Additionally, our locations provide excellent infrastructure
                    and amenities, ensuring a seamless and comfortable
                    experience for all. Each of our computer labs is spacious
                    and well-equipped with the latest technology, creating an
                    ideal environment for productive learning, training, and
                    events.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-4 col-sm-6 col-xs-12">
              <div className="course-item">
                <div className="image">
                  <img
                    src="images/courses/lab5.png"
                    alt="course image"
                    className="img-responsive"
                  />
                </div>
                <div className="content">
                  <p>
                    With our strategically chosen locations, you can enjoy the
                    convenience of hosting your sessions in vibrant and dynamic
                    settings. Choose Brainbox Solutions and unlock the
                    advantages of our conveniently located computer lab rentals
                    in these thriving cities."
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Locations;
