import React from 'react'

const HomePricing = () => {
  return (
    <>
      <section className="teacher-details padding-120 about-three ">
        <div className="container">
          <div className="row">
            <div className="col-md-6 col-sm-12 col-xs-12">
              <div className="teacher-image ">
                <img
                  src="images/about/lab1.jpg"
                  alt="teacher image"
                  className="img-responsive "
                />
              </div>
            </div>
            <div className="col-md-6 col-sm-12 col-xs-12">
              <div className="teacher-content">
                <h4 className="">Pricing</h4>
                <p className="mt-lg-5">
                  <br />
                  Pricing: At Brainbox Solutions, we offer flexible and
                  competitive pricing options for our computer lab rental
                  services. We understand that every client has unique
                  requirements, and our goal is to provide transparent and
                  affordable pricing that aligns with your specific needs. Our
                  pricing structure is designed to accommodate various
                  durations, from short-term hourly rentals to full-day or
                  extended periods. We offer competitive rates that ensure value
                  for your investment, while also providing the highest quality
                  labs equipped with the latest technology. To get a
                  personalized quote for your specific requirements, please
                  reach out to our dedicated team. We are committed to
                  delivering cost-effective solutions that meet your budget
                  without compromising on the quality and excellence that
                  defines Brainbox Solutions."
                </p>
              </div>
            </div>
          </div>
        </div>
        {/* container */}
      </section>
    </>
  );
}

export default HomePricing