import React from "react";

const HomeAbout = () => {
  return (
    <>
     
      <section className="teacher-details padding-120 about about-three">
        <div className="container">
          <div className="row ">
            <div className="col-md-6 col-sm-12 col-xs-12">
              <div className="teacher-content">
                <h3 className="text-center">About </h3>
                <p className="mt-lg-5">
                  <br />
                  Brainbox Solutions is the perfect solution for all your
                  computer lab rental needs. Our state-of-the-art computer labs
                  come fully equipped with the latest software, hardware, and
                  accessories to meet your every need. Choose from a variety of
                  rental options, from short-term to long-term, and with
                  flexible rental plans, you can count on us to provide a
                  solution that fits your specific needs. Contact us today to
                  learn more about renting the perfect computer lab for your
                  project.
                </p>
              </div>
            </div>

            <div className="col-md-6 col-sm-12 col-xs-12">
              <div className="teacher-image ">
                <img
                  src="images/about/computer.png"
                  alt="teacher image"
                  className="img-responsive mt"
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default HomeAbout;
