import React from 'react'
import HomeBanner from './home/HomeBanner'
import HomeAbout from './home/HomeAbout';
import HomePricing from './home/HomePricing';
import Locations from './home/Locations';
import HomeSlider from './home/HomeSlider';
import Achievements from './Achievements';

const Home = () => {
  return (
    <>
      {/* <HomeBanner /> */}
      <HomeSlider />
      <HomeAbout />
      <Locations />
      {/* <Achievements /> */}
      <HomePricing />
    </>
  );
}

export default Home