import React from "react";
import { Link } from "react-router-dom";

const Gallery = () => {
  return (
    <>
      <section className="page-header">
        <div className="overlay">
          <div className="container">
            <h3>Gallery</h3>
            <ul>
              <li>
                <Link to="/">Home</Link>
              </li>
              <li> - </li>
              <li> Gallery</li>
            </ul>
          </div>
        </div>
      </section>

      Comming Soon
      
      {/* <section className="teachers teachers-two padding-120">
        <div className="container">
          <div className="row">
            <div className="col-md-3 col-sm-6 col-xs-12">
              <div className="teacher-item">
                <div className="teacher-image">
                  <img
                    src="images/teachers/teacher_13.jpg"
                    alt="teacher image"
                    className="img-responsive"
                  />
                </div>
                
              </div>
              
            </div>
            <div className="col-md-3 col-sm-6 col-xs-12">
              <div className="teacher-item">
                <div className="teacher-image">
                  <img
                    src="images/teachers/teacher_14.jpg"
                    alt="teacher image"
                    className="img-responsive"
                  />
                </div>
                
              </div>
              
            </div>
            <div className="col-md-3 col-sm-6 col-xs-12">
              <div className="teacher-item">
                <div className="teacher-image">
                  <img
                    src="images/teachers/teacher_15.jpg"
                    alt="teacher image"
                    className="img-responsive"
                  />
                </div>
               
              </div>
              
            </div>
            <div className="col-md-3 col-sm-6 col-xs-12">
              <div className="teacher-item">
                <div className="teacher-image">
                  <img
                    src="images/teachers/teacher_16.jpg"
                    alt="teacher image"
                    className="img-responsive"
                  />
                </div>
              </div>
              
            </div>
          </div>
          <div className="row">
            <div className="col-md-3 col-sm-6 col-xs-12">
              <div className="teacher-item">
                <div className="teacher-image">
                  <img
                    src="images/teachers/teacher_13.jpg"
                    alt="teacher image"
                    className="img-responsive"
                  />
                </div>
                
              </div>
              
            </div>
            <div className="col-md-3 col-sm-6 col-xs-12">
              <div className="teacher-item">
                <div className="teacher-image">
                  <img
                    src="images/teachers/teacher_14.jpg"
                    alt="teacher image"
                    className="img-responsive"
                  />
                </div>
                
              </div>
              
            </div>
            <div className="col-md-3 col-sm-6 col-xs-12">
              <div className="teacher-item">
                <div className="teacher-image">
                  <img
                    src="images/teachers/teacher_15.jpg"
                    alt="teacher image"
                    className="img-responsive"
                  />
                </div>
               
              </div>
              
            </div>
            <div className="col-md-3 col-sm-6 col-xs-12">
              <div className="teacher-item">
                <div className="teacher-image">
                  <img
                    src="images/teachers/teacher_16.jpg"
                    alt="teacher image"
                    className="img-responsive"
                  />
                </div>
              </div>
              
            </div>
          </div>
          <div className="row">
            <div className="col-md-3 col-sm-6 col-xs-12">
              <div className="teacher-item">
                <div className="teacher-image">
                  <img
                    src="images/teachers/teacher_13.jpg"
                    alt="teacher image"
                    className="img-responsive"
                  />
                </div>
                
              </div>
              
            </div>
            <div className="col-md-3 col-sm-6 col-xs-12">
              <div className="teacher-item">
                <div className="teacher-image">
                  <img
                    src="images/teachers/teacher_14.jpg"
                    alt="teacher image"
                    className="img-responsive"
                  />
                </div>
                
              </div>
              
            </div>
            <div className="col-md-3 col-sm-6 col-xs-12">
              <div className="teacher-item">
                <div className="teacher-image">
                  <img
                    src="images/teachers/teacher_15.jpg"
                    alt="teacher image"
                    className="img-responsive"
                  />
                </div>
               
              </div>
              
            </div>
            <div className="col-md-3 col-sm-6 col-xs-12">
              <div className="teacher-item">
                <div className="teacher-image">
                  <img
                    src="images/teachers/teacher_16.jpg"
                    alt="teacher image"
                    className="img-responsive"
                  />
                </div>
              </div>
              
            </div>
          </div>
        </div>
      </section> */}
    </>
  );
};

export default Gallery;
