import React, { useState } from "react";
import { Link } from "react-router-dom";
import { IoMdClose } from "react-icons/io";
import { GiHamburgerMenu } from "react-icons/gi";

const Hadear = () => {
  const [ismobile, setIsmobile] = useState(0);

  return (
    <>
      {/* <header className="header-fixed">
        <div className="header-top">
          <div className="container">
            <ul className="left">
              <li>
                <span>
                  <i className="fa fa-phone" aria-hidden="true" />
                </span>{" "}
                Phone : 8801 923 154 259
              </li>
              <li>
                <span>
                  <i className="fa fa-clock-o" aria-hidden="true" />
                </span>{" "}
                Opening Time : 9:30am-5:30pm
              </li>
            </ul>
            <ul className="right">
              <li>
                <span>
                  <i className="fa fa-home" aria-hidden="true" />
                </span>
                Address : Labartisan 1205 Dhaka
              </li>
            </ul>
          </div>
        </div>

        <div className="main-menu">
          <nav className="navbar ">
            <div className="container">
              <div className="navbar-header">
                <button
                  type="button"
                  className="navbar-toggle collapsed"
                  data-toggle="collapse"
                  data-target="#bs-example-navbar-collapse-1"
                  aria-expanded="false"
                >
                  <span className="sr-only">Toggle navigation</span>
                  <span className="icon-bar" />
                  <span className="icon-bar" />
                  <span className="icon-bar" />
                </button>
                <Link className="navbar-brand" to="/">
                  <img
                    src="images/logo1.png"
                    alt="logo"
                    className="img-responsive"
                  />
                </Link>
              </div>
              <div
                className="collapse navbar-collapse"
                id="bs-example-navbar-collapse-1"
              >
                <ul
                  className="nav navbar-nav navbar-right Firstnav"
                  id="menu"
                  // style={{ display: "none" }}
                >
                  <li>
                    <Link to="/" onClick={hide}>
                      Home
                    </Link>
                  </li>
                  <li>
                    <Link to="/about" onClick={hide}>
                      About US
                    </Link>
                  </li>
                  <li>
                    <Link to="/services" onClick={hide}>
                      Services
                    </Link>
                  </li>
                  <li>
                    <Link to="/gallery" onClick={hide}>
                      Gallery
                    </Link>
                  </li>
                  <li>
                    <Link to="/contact" onClick={hide}>
                      Contact US
                    </Link>
                  </li>
                  <li></li>
                </ul>
                <form className="menu-search-form">
                  <input
                    type="text"
                    name="search"
                    placeholder="Search here..."
                  />
                  <span className="menu-search-close">
                    <i className="fa fa-times" aria-hidden="true" />
                  </span>
                </form>
              </div>
            </div>
          </nav>
        </div>
      </header> */}

      <nav className="navbar navbar-expand-lg">
        <div className="container">
          <Link className="navbar-brand">
            <div className="logo-wrapper">
              <Link
                className="d-flex justify-content-center align-items-center"
                to="/"
              >
                <img
                  src="images/logo1.png"
                  className="img-fluid"
                  alt="Edusquad"
                />
              </Link>
            </div>
          </Link>
          <div
            className={ismobile ? "moblink" : "desklink"}
            onClick={() => setIsmobile(false)}
          >
            <ul className="">
              <Link className=" " to="/">
                <li>HOME</li>
              </Link>
              <Link className=" " to="/about">
                <li>About US</li>
              </Link>
              <li>
                <Link className=" " to="/services">
                  <li> Services </li>
                </Link>
              </li>
              <li>
                <Link className=" " to="/gallery">
                  <li> Gallery </li>
                </Link>
              </li>

              <li>
                <Link className=" " to="/contact">
                  <li> CONTACT US</li>
                </Link>
              </li>
            </ul>
          </div>

          <button className="vavbutn" onClick={() => setIsmobile(!ismobile)}>
            {ismobile ? <IoMdClose /> : <GiHamburgerMenu />}
          </button>
        </div>
      </nav>
    </>
  );
};

export default Hadear;
