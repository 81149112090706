import React from "react";

const Achievements = () => {
  return (
    <>
      <section className="achievements section-notch">
        <div className="overlay padding-120">
          <div className="container">
            <div className="row">
              <div className="col-md-3 col-sm-6 col-xs-12">
                <div className="achievement-item">
                  <i className="icon flaticon-student" />
                  <span className="counter">2500</span>
                  <p>Clients</p>
                </div>
                {/* achievement item */}
              </div>
              <div className="col-md-3 col-sm-6 col-xs-12">
                <div className="achievement-item">
                  <i className="icon flaticon-cup" />
                  <span className="counter">912</span>
                  <p>Best Awards Won</p>
                </div>
                {/* achievement item */}
              </div>
              <div className="col-md-3 col-sm-6 col-xs-12">
                <div className="achievement-item">
                  <i className="icon flaticon-drawing" />
                  <span className="counter">370</span>
                  <p>Lab</p>
                </div>
                {/* achievement item */}
              </div>
              <div className="col-md-3 col-sm-6 col-xs-12">
                <div className="achievement-item">
                  <i className="icon flaticon-book" />
                  <span className="counter">648</span>
                  <p>Our Total Courses</p>
                </div>
                {/* achievement item */}
              </div>
            </div>
            {/* row */}
          </div>
          {/* container */}
        </div>
        {/* overlay */}
      </section>
    </>
  );
};

export default Achievements;
