import React from 'react'
import Hadear from './Hadear'
import { Outlet } from "react-router-dom";
import Footer from './Footer';

const MainLayOut = () => {
  return (
    <>
      <Hadear />
      <Outlet />
      <Footer />
    </>
  );
}

export default MainLayOut